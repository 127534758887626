import React from 'react'
import PropTypes from 'prop-types'
import { Link, StaticQuery, graphql } from 'gatsby'

import './header.css'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }

  componentDidMount () {
    let that = this
    this.timeoutId = setTimeout(() => {
      that.setState({loading: false})
    }, 100);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
  }

  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
              totalCount
              edges {
                node {
                  id
                  frontmatter {
                    title
                    order
                  }
                  fields {
                    slug
                  }
                  excerpt
                }
              }
            }
          }
        `}
        render={data => (<header id="header" className={this.state.loading ? 'loading' : ''}>
          <div className="logo"></div>
          <div className="content">
            <div className="inner">
              <h1>{this.props.siteTitle}</h1>
              <p>{this.props.description}</p>
            </div>
          </div>
          <nav>
            <ul>
              {data.allMarkdownRemark.edges.map(({ node }) => (
                <li key={node.id}>
                  <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                </li>
              ))}
            </ul>
          </nav>
        </header>)}
      />
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Header
