import React from 'react'

import Layout from '../components/layout'
import Header from '../components/header';
import { StaticQuery, graphql } from 'gatsby';

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <Layout blurBackground={false}>
        <Header siteTitle={data.site.siteMetadata.title} description={data.site.siteMetadata.description}>
        </Header>
      </Layout>
    )}
  />
)

export default IndexPage
